import React from 'react';
import './NewTicketComponent.scss';
import { Accordion, Button, Alert, Card, ListGroup, Form, Badge } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {BACKEND_URL,BACKEND_SSE_URL} from '../../consts';

function withParams(Component){
  return props => <Component {...props} params={useParams()}/>;
}
const sse = `${BACKEND_SSE_URL}?topics=new_tickets`;

class NewTicketForEventComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      ticketsToAdd:[],
      isFetching: false,
      universaltickets:false,
      printOnPattern:true,
      event: {
        name: '',
        id: '',
        tribunes: [],
        ticketTypes: [],
      },
    }
    this.renderLabel = this.renderLabel.bind(this);
    this.addTicketToAdd = this.addTicketToAdd.bind(this);
    this.removeTicketToAdd = this.removeTicketToAdd.bind(this);
    this.toggleNewTicket = this.toggleNewTicket.bind(this);
    this.createNewTickets = this.createNewTickets.bind(this);
    this.fetchEvent = this.fetchEvent.bind(this);
    this.getSectorIdOfCarnet = this.getSectorIdOfCarnet.bind(this);
    this.mountSSE = this.mountSSE.bind(this);
    this.changeUniversalTickets = this.changeUniversalTickets.bind(this);
    this.changePrintOnPattern = this.changePrintOnPattern.bind(this);
    this.updateTicketAdminNote = this.updateTicketAdminNote.bind(this);
    this.updateTicketPublicComment = this.updateTicketPublicComment.bind(this);
  }

  componentDidMount(){
    this.fetchEvent();
    // this.mountSSE();
  }

  fetchEvent(){
    fetch(`${BACKEND_URL}/api/event/${this.props.params.id}/with-carnets-and-tickets`,{
      "credentials": "include",
    })
      .then(data=>data.json())
      .then(data=>this.setState({event:{...data.event}}))
      .catch(e=>console.warn(e));
  }

  mountSSE(){
    const eventSource = new EventSource(sse);
    eventSource.onmessage = event => {
      const res = JSON.parse(event.data);
      console.log(res);
    }
  }

  renderLabel(){
    let {event} = this.state;
    return (
      <div className="label">
        <h2>{event.name}</h2>
        <div className="tribunes">
          {this.state.event?.tribunes.map((t,k) => this.renderTribune(t,k))}
        </div>
      </div>
    )
  }

  renderTribune(t,k){
    let carnetSeats=0;
    let ticketSeats=0;
    let usedSeats=0;
    t.sectors.forEach(s => {
      s.rows.forEach(r => {
        carnetSeats+=r.carnetSeats.length;
        ticketSeats+=r.ticketSeats.length;
        r.carnetSeats.forEach(cs => usedSeats+=(cs.isInUse));
        r.ticketSeats.forEach(ts => usedSeats+=(ts.isInUse));
      })
    })
    return (
      <div className={`tribune${t.hasNumbers?' has-number':' no-numbers'}`} key={`tribune${k}`}>
        <h3>{t.name} (karnetów: {carnetSeats}, biletów: {ticketSeats}, aktywnych: {usedSeats})</h3>
        <div className="sectors">
          {t.sectors.map((s,k) => this.renderSector(s,k,t.hasNumbers))}
        </div>
      </div>
    )
  }

  renderSector(s,k,hasNumbers){
    let howManySeats = 0;
    let howManyDisabledSeats = 0;
    let howManyCarnetSeats = 0;
    let howManyTicketSeats = 0;
    let rowIds = [];
    let isNumberingPerRow = s.isNumberingPerRow;
    s.rows.forEach(r => {
      rowIds.push(r.id);
      howManySeats+=r.numberOfSeats;
      howManyDisabledSeats+=r.disabledSeats.length;
      howManyCarnetSeats+=r.carnetSeats.length;
      howManyTicketSeats+=r.ticketSeats.length;
    });
    let howManyTicketsToAdd = 0;
    this.state.ticketsToAdd.forEach(t => {
      if(rowIds.includes(t.row)) howManyTicketsToAdd++;
    })
    let howManyEnabledSeats = howManySeats - howManyDisabledSeats;
    let howManyBusySeats = howManyTicketsToAdd + howManyCarnetSeats + howManyTicketSeats;
    let howManyEmptySeats = howManyEnabledSeats - howManyBusySeats;
    let currentSeats = howManyEnabledSeats;
    let howManyInUse = 0;
    s.rows.forEach(r => {
      r.carnetSeats.forEach(c => {
        if(c.isInUse) howManyInUse++;
      })
      r.ticketSeats.forEach(t => {
        if(t.isInUse) howManyInUse++;
      })
    })
    return (
      <div className="sector" key={`sector${k}`} onClick={()=>!hasNumbers&&this.addNewTicketForSector(s)}>
        <h3>Sektor: {s.name} (karnetów: {howManyCarnetSeats}, biletów: {howManyTicketSeats}, aktywnych: {howManyInUse})</h3>
        {!hasNumbers && (
          <div className="available-places">
            <h5>
              Ilość dostępnych miejsc: {howManyEmptySeats}/{howManyEnabledSeats}
            </h5>
            <small>Aktualnie wybranych do dodania: {howManyTicketsToAdd}
            <br/>(Na tej trybunie nie ma numeracji miejsc)</small>
          </div>
        )}
        <div className="rows">
          {s.rows.map((r,k) => {
            currentSeats = !isNumberingPerRow ? (currentSeats - r.numberOfSeats + r.disabledSeats.length) : 0;
            return this.renderRow(r,k,hasNumbers,currentSeats);
          })}
        </div>
      </div>
    )
  }

  renderRow(r,k,hasNumbers,startNumber=0){
    return (
      <div className="row" key={`row${k}`}>
        <span className="row-label">{r.label}</span>
        {this.renderSeats(r,hasNumbers,startNumber)}
      </div>
    )
  }

  renderSeats(row,hasNumbers,startNumber){
    // tbd: remove "carnetSeats" - get info about current carnets from endpoint "current carnets"
    let seatNumber = 1+startNumber;
    return (
      Array(row.numberOfSeats).fill(0).map((s,k) => {
        let sn = seatNumber*1;
        let isDisabled = row.disabledSeats.includes(k+1);
        let isSelected = !isDisabled && this.state.ticketsToAdd.find(c => ((c.row === row.id) && (c.seatNr === sn)));
        let isBusyWithCarnet = !isDisabled && row.carnetSeats.find(cs => cs.seat === sn);
        let isBusyWithTicket = !isDisabled && row.ticketSeats.find(cs => cs.seat === sn);
        let isInUse = (isBusyWithTicket && isBusyWithTicket.isInUse) || (isBusyWithCarnet && isBusyWithCarnet.isInUse);
        let isBusy = isBusyWithCarnet || isBusyWithTicket;
        let toDisplay = seatNumber;
        if(!isDisabled) seatNumber++;
        return (
          <span
            className={`seat${isBusyWithCarnet?' busy-carnet':''}${isBusyWithTicket?' busy-ticket':''}${isDisabled?' disabled':''}${isSelected?' selected':''}${isInUse?' in-use':''}`}
            onClick={()=>hasNumbers&&!isDisabled&&!isBusy&&this.toggleNewTicket(row,sn)}
            key={`r${row.label}s${k}`}
          >
            {(isDisabled||!hasNumbers)?'':toDisplay}
          </span>
        )
      })
    )
  }

  removeTicketToAdd(row,seatNumber){
    this.setState({
      ticketsToAdd: this.state.ticketsToAdd.filter(c => !((c.row === row.id) && (c.seatNr === seatNumber))),
    })
  }

  removeTicketToAddByRowId(rowId,seatNumber){
    if(window.confirm('Czy na pewno usunąć?')){
      this.setState({
        ticketsToAdd: this.state.ticketsToAdd.filter(c => !((c.row === rowId) && (c.seatNr === seatNumber))),
      });
    }
  }

  addTicketToAdd(row,seatNumber){
    // tbd: provide default ticket type
    this.setState({
      ticketsToAdd: [...this.state.ticketsToAdd, {'row': row.id, 'seatNr': seatNumber, 'ticketType': ''}],
    })
  }

  toggleNewTicket(row,seatNumber){
    let ticketExists = this.state.ticketsToAdd.find(c => (c.row === row.id) && (c.seatNr === seatNumber));
    if(ticketExists) this.removeTicketToAdd(row,seatNumber);
    else this.addTicketToAdd(row,seatNumber);
  }

  addNewTicketForSector(sector){
    let numberOfSeatToReserve = null;
    let sectorToReserveSeat = sector.rows.find(row => {
      let seatNumber = 1;
      let seatToReserve = Array(row.numberOfSeats).fill(1).find((seat,key)=>{
        let sn = seatNumber*1;
        if(sn > row.numberOfSeats) return false;
        let isDisabled = row.disabledSeats.includes(key+1);
        if(!isDisabled) seatNumber++;
        let isSelected = !isDisabled && this.state.ticketsToAdd.find(c => ((c.row === row.id) && (c.seatNr === sn)));
        let isBusyWithCarnet = row.carnetSeats.find(cs => cs.seat === sn);
        let isBusyWithTicket = row.ticketSeats.find(cs => cs.seat === sn);
        let isBusy = isBusyWithCarnet || isBusyWithTicket;
        let isThisPlaceAllowedToReserve = !isDisabled && !isSelected && !isBusy;
        if(isThisPlaceAllowedToReserve){
          numberOfSeatToReserve = sn;
          return true;
        }
        return false;
      })
      return !!seatToReserve;
    })
    if(sectorToReserveSeat) this.addTicketToAdd(sectorToReserveSeat, numberOfSeatToReserve);
  }

  createNewTickets(ev){
    ev.preventDefault();
    let tickets = this.state.ticketsToAdd.map(t => ({
      row: t.row,
      event: this.state.event.id,
      seatNr: t.seatNr,
      ticketType: t.ticketType,
    }));
    fetch(`${BACKEND_URL}/api/ticket/new`,{
      "credentials": "include",
      method: 'POST',
      body: JSON.stringify({'tickets':tickets}),
      headers: {
        'Content-type': 'application/json',
      }
    })
      .then(data => data.json())
      .then(data => {
        if(data.success){
          data.ids.forEach(id => {
            this.downloadTicket(id);
          });
        } else {
          window.alert(data.message);
          if(data.shouldReload){
            this.setState({
              ticketsToAdd: [],
            })
            this.fetchEvent();
          }
        }
        this.setState({
          ticketsToAdd: [],
        });
        this.fetchEvent();
      })
      .catch(err=>console.warn(err));
  }

  removeTicket(id){
    if(window.confirm('Czy potwierdzasz usunięcie biletu?')){
      fetch(`${BACKEND_URL}/api/ticket/${id}`,{
        "credentials": "include",
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
        }
      })
      .then(data => this.fetchEvent())
      .catch(error => console.warn(error));
    }
  }

  updateTicketAdminNote(id,note){
    this.setState({isFetching: true});
    fetch(`${BACKEND_URL}/api/ticket/${id}/note`,{
      "credentials": "include",
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({'note':note}),
    })
    .then(data => this.fetchEvent())
    .catch(error => console.warn(error))
    .finally(()=>this.setState({isFetching:false}))
  }

  updateTicketPublicComment(id,note){
    this.setState({isFetching: true});
    fetch(`${BACKEND_URL}/api/ticket/${id}/public-note`,{
      "credentials": "include",
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({'publicNote':note}),
    })
    .then(data => this.fetchEvent())
    .catch(error => console.warn(error))
    .finally(()=>this.setState({isFetching:false}))
  }

  changeUniversalTickets(ev){
    this.setState({
      universaltickets: ev.target.checked,
    })
  }

  changePrintOnPattern(ev){
    this.setState({
      printOnPattern: ev.target.checked,
    })
  }

  downloadTicket(id){
    const {universaltickets, printOnPattern} = this.state;
    fetch(`${BACKEND_URL}/api/ticket/${id}/download/${universaltickets?1:0}/${printOnPattern?1:0}`,{
      "credentials": "include",
      headers: {
        "Content-type": "application/json",
      }
    })
      .then(data=>data.blob())
      .then(data=>{
        let file = window.URL.createObjectURL(data);
        let newWindow = window.open();
        newWindow.location.assign(file);
      })
      .catch(err=>console.warn(err));
  }

  renderPresentTickets(){
    const {event,isFetching} = this.state;
    return event && event.tribunes ? (
      <Card>
        <Card.Body>
          <Card.Title>Bilety wygenerowane na to wydarzenie:</Card.Title>
          <Card.Text>
            {event?.tribunes.map((tribune,key)=>(
              <div className="present-tickets-tribune" key={`tribune${key}`}>
                <Alert variant="primary">{tribune.name}</Alert>
                <Accordion>
                  {tribune.sectors.map((sector,sectorkey) =>(
                    <Accordion.Item eventKey={sectorkey}>
                      <Accordion.Header>Sektor: {sector.name}</Accordion.Header>
                      <Accordion.Body>
                        <div className="present-tickets-sector" key={`t${key}sector${sectorkey}`}>
                          <ListGroup>
                            {sector.rows.map((row,rowkey) => row.ticketSeats.map((seat,seatkey) => (
                              <ListGroup.Item key={`t${key}s${sectorkey}r${rowkey}${seatkey}`}>
                                {this.reservedSeatLabel({'row':row.id,'seatNr':seat.seat})}<br/>
                                {seat.email && (<><small>Wysłany: {seat.email}</small><br/></>)}
                                {seat.transactionId && (<><small>Transakcja: {seat.transactionId}</small><br/></>)}
                                {seat.createTime && (<><small>Utworzony: {seat.createTime}</small><br/></>)}
                                {seat.generateTime && (<small>Pobrany: {seat.generateTime}</small>)}
                                <div className={`admin-comment ${(!seat.email) && !seat.adminNote ? 'expected' : ''}`}>
                                Komentarz administratora: <Form.Control
                                  type="text"
                                  defaultValue={seat.adminNote}
                                  disabled={isFetching}
                                  onBlur={ev=>this.updateTicketAdminNote(seat.ticketId,ev.target.value)}
                                />
                                </div>
                                {!seat.email && (<div className={`admin-comment`}>
                                Komentarz publiczny: <Form.Control
                                  type="text"
                                  defaultValue={seat.publicComment}
                                  disabled={isFetching}
                                  onBlur={ev=>this.updateTicketPublicComment(seat.ticketId,ev.target.value)}
                                />
                                </div>)}
                                {!seat.email && (<Button
                                  variant="danger"
                                  onClick={()=>this.removeTicket(seat.ticketId)}
                                  disabled={isFetching}
                                >
                                  Usuń
                                </Button>)}
                                {!!seat.email && (<Badge bg="danger">Bilet zakupiony online - nie powinien być modyfikowany ani dodatkowo pobierany!</Badge>)}
                                <Button
                                  variant="info"
                                  onClick={()=>this.downloadTicket(seat.ticketId)}
                                  disabled={isFetching}
                                >
                                  Pobierz
                                </Button>
                              </ListGroup.Item>
                            )))}
                          </ListGroup>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            ))}
          </Card.Text>
        </Card.Body>
      </Card>
    ) : (<></>);
  }

  reservedSeatLabel(c){
    const event = this.state.event;
    let t = event?.tribunes.find(t => t.sectors.find(s => s.rows.find(r => r.id === c.row)));
    let s = t?.sectors.find(s => s.rows.find(r => r.id === c.row));
    let r = s?.rows.find(r => r.id === c.row);
    let label = `Trybuna: ${t.name}, sektor: ${s.name}`;
    label+= (t.hasNumbers) ? `, rząd ${r.label}, miejsce nr ${c.seatNr}` : ' (nienumerowane)';
    return label;
  }

  getSectorIdOfCarnet(c){
    let rowId = c.row;
    let tribune = this.state.event.tribunes.find(t=>t.sectors.find(s=>s.rows.find(r=>r.id === rowId)));
    let sector = tribune.sectors.find(s=>s.rows.find(r=>r.id === rowId));
    return sector.id;
  }

  updateTicketTicketType(t,ev){
    let newCarnet = {
      row: t.row,
      seatNr: t.seatNr,
      ticketType: ev.target.value,
    }
    let tickets = this.state.ticketsToAdd.map(tt => ((tt.row === t.row) && (tt.seatNr === t.seatNr)) ? newCarnet : tt);
    this.setState({
      ticketsToAdd: tickets,
    })
  }

  renderTicketsToAdd(){
    return (
      <Card>
        <Card.Body>
          <Card.Title>Wybrane bilety do wygenerowania:</Card.Title>
          <Card.Text>
            <ListGroup>
              {this.state.ticketsToAdd.map((c,k) => (
                <ListGroup.Item key={`cg${k}`}>
                  {this.reservedSeatLabel(c)}
                  <div className="select-ticket">
                    <b>Wybierz rodzaj biletu:</b>
                    <Form.Select
                      value={c.ticketType}
                      className="select-ticket-type"
                      onChange={(ev)=>this.updateTicketTicketType(c,ev)}
                    >
                      <option value="">Wybierz typ biletu</option>
                      {this.state.event.ticketTypes[this.getSectorIdOfCarnet(c)].map((tt,ttnr)=>(
                        <option key={`o${ttnr}`} value={tt.id}>{tt.name} ({tt.value} zł)</option>
                      ))}
                    </Form.Select>
                  </div>
                  <Button variant="danger" onClick={ev=>{ev.preventDefault();this.removeTicketToAddByRowId(c.row,c.seatNr)}}>
                    Usuń z listy
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <div className="universal-tickets-sticker">
              <Form.Group>
                <Form.Label>Wydrukuj bilety jako uniwersalne (do sprzedaży "hurtowej" - bez druku typu biletu)</Form.Label>
                <Form.Check type="switch" checked={this.state.universaltickets} value="1" onChange={this.changeUniversalTickets} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Wydruk na gotowym wzorcu</Form.Label>
                <Form.Check type="switch" checked={this.state.printOnPattern} value="1" onChange={this.changePrintOnPattern} />
              </Form.Group>
            </div>
            <Button onClick={this.createNewTickets}>Stwórz wybrane bilety</Button>
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }

  render(){
    return (
      <div className="tickets">
        {this.renderLabel()}
        {this.renderTicketsToAdd()}
        {this.renderPresentTickets()}
      </div>
    )
  }
}

export default withParams(NewTicketForEventComponent);
